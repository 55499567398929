import React from "react";
import PropTypes from "prop-types";
import Tilt from "react-parallax-tilt";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import { animCoverProps__logo, isMobile } from "../helpers";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { TimelineLite, Power4 } from "gsap";
import {
  Layout,
  Content,
  HTMLContent,
  PreviewCompatibleImage,
  SectionWrapper,
  Icon,
  HTMLBody,
} from "../components";
import "../styles/_release.scss";

export const ReleaseTemplate = ({
  content,
  contentComponent,
  artistSlug,
  author,
  name,
  featuredimage,
  tags,
  dsp,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  const tl = new TimelineLite();

  const show = () => {
    const timing = { duration: 2.5, ease: Power4.easeOut };
    return tl
      .from(".release__image > div", {
        xPercent: 300,
        ...timing,
      })
      .from(
        ".release__info > div",
        {
          xPercent: -300,
          ...timing,
        },
        "<"
      )
      .from(
        ".release__info > .section > div, .release__info > .section > header",
        {
          yPercent: 300,
          stagger: { amount: 0.225, from: "start" },
          ...timing,
        },
        "<.5"
      );
  };

  React.useEffect(() => {
    show();
    return () => {
      show().clear();
    };
  }, []);
  return (
    <SectionWrapper className="release half-height container-width-m">
      {helmet || ""}
      <div className="release__wrapper">
        {isMobile ? (
          <div className="release__image">
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredimage,
                alt: `${name} - ${author}`,
              }}
            ></PreviewCompatibleImage>
          </div>
        ) : typeof featuredimage !== "string" ? (
          <Tilt
            perspective={800}
            tiltMaxAngleX={4}
            tiltMaxAngleY={4}
            tiltReverse={true}
            glareEnable={true}
            glareMaxOpacity={0.25}
            className="release__image cursor-trigger"
          >
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredimage,
                alt: `${name} - ${author}`,
              }}
            />
          </Tilt>
        ) : (
          <div
            className="release__image"
            style={{
              background: `url(${featuredimage}) no-repeat`,
              backgroundSize: "cover",
            }}
          />
        )}
        <div className="release__info">
          <div className="release__info__details">
            <div className="release__info--author">
              {!artistSlug ? (
                <>{author}</>
              ) : (
                <AniLink
                  cover
                  to={`/artists/bio/${artistSlug}`}
                  {...animCoverProps__logo}
                >
                  {author}
                </AniLink>
              )}
            </div>
            <div className="release__info--name">{name}</div>
          </div>

          <HTMLBody className="release__info-body" content={content} />

          {dsp && dsp.links.length ? (
            <SectionWrapper
              title={`Listen On`}
              className="full container-width-s min-height release__info__dsp"
            >
              <ul className="taglist">
                {dsp.links.map(({ type, url }) => (
                  <li key={type + `dsp`}>
                    <a href={url} target="_blank" className="link">
                      <span>
                        {type}
                        <Icon name={type} />
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            </SectionWrapper>
          ) : null}
        </div>
      </div>
      <div className="release__footer">
        {tags && tags.length ? (
          <div className="page__footer__tags">
            <h1 className="page__footer__tags--title">Tags</h1>
            <ul className="page__footer__tags--list">
              {tags.map((tag) => (
                <li key={tag + `tag`}>
                  <Link to={`/tags/${kebabCase(tag)}/`} className="link">
                    <span>{tag}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </SectionWrapper>
  );
};

ReleaseTemplate.propTypes = {
  contentComponent: PropTypes.func,
  author: PropTypes.string,
  artistSlug: PropTypes.string,
  name: PropTypes.string,
  featuredimage: PropTypes.object || PropTypes.string,
  dsp: PropTypes.object,
  helmet: PropTypes.object,
};

const Release = ({ data }) => {
  const { markdownRemark: post } = data;
  const songName = `${post.frontmatter.author} - ${post.frontmatter.name}`;
  return (
    <Layout>
      <ReleaseTemplate
        contentComponent={HTMLContent}
        content={post.html}
        author={post.frontmatter.author}
        artistSlug={post.frontmatter.artistSlug}
        name={post.frontmatter.name}
        dsp={post.frontmatter.dsp}
        featuredimage={post.frontmatter.featuredimage}
        helmet={
          <Helmet titleTemplate="REAL CVLTVRE | %s">
            <title>{songName}</title>
            <meta property="og:title" content={`REAL CVLTVRE | ${songName}`} />
            <meta name="description" content={`${songName} - Listen now`} />
            <meta name="og:description" content={`${songName} - Listen now`} />
            <meta
              property="og:image"
              content={post.frontmatter.featuredimage.childImageSharp.fluid.src}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
      />
    </Layout>
  );
};

Release.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Release;

export const pageQuery = graphql`
  query ReleaseByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        author
        artistSlug
        name
        tags
        dsp {
          links {
            type
            url
          }
        }
        featuredimage {
          childImageSharp {
            fluid(
              maxWidth: 550
              quality: 100
              srcSetBreakpoints: [100, 200, 400, 550]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
